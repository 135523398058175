import non_alc1 from "./images/non_alc1.png";
import non_alc2 from "./images/non_alc2.png";
import non_alc3 from "./images/non_alc3.png";

import ow1 from "./images/ow1.svg";
import ow2 from "./images/ow2.svg";
import ow3 from "./images/ow3.svg";
import ow4 from "./images/ow4.svg";
import ow5 from "./images/ow5.svg";
import ow6 from "./images/ow6.svg";
import ow7 from "./images/ow7.svg";
import ow8 from "./images/ow8.svg";

import our_whisky1 from "./images/our_whisky1.svg";
import our_whisky2 from "./images/our_whisky2.svg";



import non_alc_summer88_header from "./images/headers/non_alc_summer88_header.png";
import non_alc_spring88_header from "./images/headers/non_alc_spring88_header.png";
// import non_alc_galiotto_header from "./images/headers/non_alc_galiotto_header.svg";

import galiotto_p1_header from "./images/headers/galiotto_p1_header.png";
import betheone_header from "./images/headers/betheone_header.png";
import betheone_p1_header from "./images/headers/betheone_p1_header.svg";
import dudies_header from "./images/headers/dudies_header.png";
import dudies_header_video from "./images/dudies_header_video.mp4";
import mayan_header from "./images/headers/mayan_header.png";
import mayan_p1_header from "./images/headers/mayan_p1_header.svg";
import magic_header from "./images/headers/magic_header.png";
import magic_p1_header from "./images/headers/magic_p1_header.png";
import fseason_header from "./images/headers/fseason_header.svg";
import fseason_p1_header from "./images/headers/fseason_p1_header.png";
import fseason_p2_header from "./images/headers/fseason_p2_header.png";
import luigi_header from "./images/headers/luigi_header.png";
import luigi_p1_header from "./images/headers/luigi_p1_header.svg";
import mayan6_header from "./images/headers/mayan6_header.png";
import goldener_header from "./images/headers/goldener_header.png";
import goldener_p1_header from "./images/headers/goldener_p1_header.svg";
import ogilhinn_header from "./images/headers/ogilhinn_header.png";
import betheone_whisky_header from "./images/headers/betheone_whiskey_header.svg";
import ogilhinn_p1_header from "./images/headers/ogilhinn_p1_header.jpg";
import whiskys_header from "./images/headers/whiskys_header.png";



import summer88_p1 from "./images/products/summer88_p1.png";
import spring88_p1 from "./images/products/spring88_p1.png";
import galiotto_p1 from "./images/products/galiotto_p1.png";
import galiotto_inner from "./images/products/galiotto_inner.png";

import betheone_p1 from "./images/products/betheone_p1.png";
import betheone_p2 from "./images/products/betheone_p2.png";
import betheone_p3 from "./images/products/betheone_p3.png";
import betheone_p4 from "./images/products/betheone_p4.png";
import betheone_p5 from "./images/products/betheone_p5.png";
import betheone_p6 from "./images/products/betheone_p6.png";
import betheone_p7 from "./images/products/betheone_p7.png";
import betheone_p8 from "./images/products/betheone_p8.png";
import betheone_p9 from "./images/products/betheone_p9.png";
import betheone_p10 from "./images/products/betheone_p10.png";
import betheone_p11 from "./images/products/betheone_p11.png";

import dudies_p1 from "./images/products/dudies_p1.png";
import dudies_p2 from "./images/products/dudies_p2.png";
import dudies_p3 from "./images/products/dudies_p3.png";

import mayan_p1 from "./images/products/mayan_p1.png";
import mayan_p2 from "./images/products/mayan_p2.png";
import mayan_p3 from "./images/products/mayan_p3.png";

import magic_p1 from "./images/products/magic_p1.png";
import magic_p2 from "./images/products/magic_p2.png";
import magic_p3 from "./images/products/magic_p3.png";
import magic_p4 from "./images/products/magic_p4.png";
import magic_p5 from "./images/products/magic_p5.png";
import magic_p6 from "./images/products/magic_p6.png";
import magic_p7 from "./images/products/magic_p7.png";

import fseason_p1 from "./images/products/fseason_p1.png";
import fseason_p2 from "./images/products/fseason_p2.png";
import fseason_p3 from "./images/products/fseason_p3.png";
import fseason_p4 from "./images/products/fseason_p4.png";

import luigi_p1 from "./images/products/luigi_p1.png";
import luigi_p2 from "./images/products/luigi_p2.png";
import luigi_p3 from "./images/products/luigi_p3.png";

import mayan6_p1 from "./images/products/mayan6_p1.png";
import mayan6_p2 from "./images/products/mayan6_p2.png";
import mayan6_p3 from "./images/products/mayan6_p3.png";
import mayan6_p4 from "./images/products/mayan6_p4.png";
import mayan6_p5 from "./images/products/mayan6_p5.png";
import mayan6_p6 from "./images/products/mayan6_p6.png";

import goldener_p1 from "./images/products/goldener_p1.png";
import goldener_p2 from "./images/products/goldener_p2.png";
import goldener_p3 from "./images/products/goldener_p3.png";
import goldener_p4 from "./images/products/goldener_p4.png";

import ogilhinn_p1 from "./images/products/ogilhinn_p1.png";
import ogilhinn_p2 from "./images/products/ogilhinn_p2.png";
import ogilhinn_p3 from "./images/products/ogilhinn_p3.png";
import ogilhinn_p4 from "./images/products/ogilhinn_p4.png";
import ogilhinn_p5 from "./images/products/ogilhinn_p5.svg";
import ogilhinn_p6 from "./images/products/ogilhinn_p6.png";
import ogilhinn_p7 from "./images/products/ogilhinn_p7.png";
import ogilhinn_p8 from "./images/products/ogilhinn_p8.svg";
import ogilhinn_p9 from "./images/products/ogilhinn_p9.png";
import ogilhinn_p10 from "./images/products/ogilhinn_p10.png";
import betheone_whisky_p1 from "./images/products/betheone_whiskey_p1.png";






const data = [
    {
        id: 1,
        target: "non-alcoholic",
        link: '/non-alcoholic/summerof88',
        img: non_alc1,
        productPageInfo: {
            title: "Summer of 88",
            desc: "Summer of 88 defines both our favorite season and the number that is believed to bring good luck. Australians love their summer, a beautiful time of the year full of leisure, fun times and carefree living. It is indeed the season that expresses how truly lucky we are.",
            pageHeader: non_alc_summer88_header,
            mainLink: "NON-ALCOHOLIC", mainLinkhref: "non-alcoholic",
            products: [
                {productImg: summer88_p1, productName: "DARK GRAPE JUICE", productPrice: "72", productLink: "/non-alcoholic/summerof88/1", category: "Summer of 88", linkName: "DARK GRAPE JUICE", qty: "750ml",
                    viewedDetails: {prodHeader: galiotto_p1_header, prodImage: summer88_p1, title: "Summer of 88", desc: "This grape juice has a bright dark purple color and has a clear smell Fruity, floral, with distinct honey and potato flavors. Details Vapor bubbles that are boring and endless. Mid-taste buds can feel full-mouthed and chewable rich in juicy fruit flavours.", region: "Australia", variety: "Blend", wine_style: "Sweet", vintage: "NV", alcohol: "0%", volume: "750ml"}},
            ]
        }
    },
    {
        id: 2,
        target: "non-alcoholic",
        link: '/non-alcoholic/springof88',
        img: non_alc2,
        productPageInfo: {
            title: "Spring of 88",
            desc: "This grape juice shows bright, light yellow colour and a fresh bouquet, with fine and smooth bubbles. The palate is medium bodied and rich in full fruit flavours.",
            pageHeader: non_alc_spring88_header,
            mainLink: "NON-ALCOHOLIC", mainLinkhref: "non-alcoholic",
            products: [
                {productImg: spring88_p1, productName: "100% White Sparkling Grape Juice", productPrice: "72", productLink: "/non-alcoholic/springof88/1", category: "Spring of 88", linkName: "SPARKLING WHITE GRAPE JUICE", qty: "750ml",
                viewedDetails: {prodHeader: non_alc_spring88_header, prodImage: spring88_p1, title: "Spring of 88", desc: "This grape juice shows bright, light yellow colour and a fresh bouquet, with fine and smooth bubbles. The palate is medium bodied and rich in full fruit flavours.", region: "South Australia", variety: "White Blend", wine_style: "Sweet", vintage: "NV", alcohol: "0%", volume: "750ml"}},
            ]
        }
    },
    {
        id: 3,
        target: "non-alcoholic",
        link: '/non-alcoholic/galiotto',
        img: non_alc3,
        productPageInfo: {
            title: "Galiotto",
            desc: "Galiotto Integral Red Grape Juice is now certified with the Pure Grape Juice Seal.Certified by Instituto Totum, this seal is only for juices that are really 100% pure: \n-Without adding any kind of preservative or antioxidant; \n-No added sugar; \n-Without adding water.",
            pageHeader: non_alc_summer88_header,
            mainLink: "NON-ALCOHOLIC", mainLinkhref: "non-alcoholic",
            products: [
                {productImg: galiotto_p1, productName: "Dark Grape Juice", productPrice: "72", productLink: "/non-alcoholic/galiotto/1", category: "Galiotto", linkName: "DARK GRAPE JUICE", qty: "300ml/500ML/1L/1.5L",
                viewedDetails: {prodHeader: galiotto_p1_header, prodImage: galiotto_inner, title: "Galiotto", desc: "Galiotto Integral Red Grape Juice is now certified with the Pure Grape Juice Seal. Certified by Instituto Totum, this seal is only for juices that are really 100% pure: \n-Without adding any kind of preservative or antioxidant; \n-No added sugar; \n-Without adding water.", region: "South Australia", variety: "Dark Grape", wine_style: "Sweet", vintage: "NV", alcohol: "0%", volume: "300ml/500ML/1L/1.5L"}},
            ]
        }
    },
    {
        id: 4,
        target: "wines",
        link: '/wines/betheone',
        img: ow1,
        productPageInfo: {
            title: "Betheone",
            desc: "Our Be the One wines are an expression of how we are all driven by passions. While those passions can be family, love, travel and food, our great passion is winemaking. For us this is our ‘one’, our one great passion.",
            pageHeader: betheone_header,
            mainLink: "WINES", mainLinkhref: "wines",
            products: [
                // {productImg: betheone_p1, productName: "SELECTION SHIRAZ ROSE", productPrice: "72", productLink: "/wines/betheone/1", category: "Betheone", linkName: "SELECTION SHIRAZ ROSE", qty: "750ml",
                //     viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p1, title: "Selection Shiraz Rose", desc: "This rose is a ripe and fruity example of this classic wine style. The wine has a pale, dusty peach colour. The nose is filled with fresh aromas of strawberry, raspberry, boiled lollies and black forest cake. The palate is sweet, light and fruity, with more strawberry fruit, balanced tannins and lingering acidity.", region: "South Australia", variety: "contact for more details", alcohol: "12%", volume: "750ml"}},
                // {productImg: betheone_p2, productName: "SELECTION SWEET RED", productPrice: "72", productLink: "/wines/betheone/2", category: "Betheone", linkName: "SELECTION SWEET RED", qty: "750ml",
                //     viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p2, title: "SELECTION SWEET RED", desc: "This Sweet Red is a fresh fruit driven example of this classic varietal. Ripe tannins and complex acidity lend to the overall perception of a smooth,enjoyable Sweet Red, with a nice persistence of flavour that lingers well on the palate. Perfect by itself or as an accompaniment to food. ", region: "South Australia", variety: "contact for more details", alcohol: "12%", volume: "750ml"}},
                {productImg: betheone_p3, productName: "SELECTION SHIRAZ", productPrice: "72", productLink: "/wines/betheone/1", category: "Betheone", linkName: "SELECTION SHIRAZ", qty: "750ml",
                    viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p3, title: "SELECTION SHIRAZ", desc: "Bright red with ruby hues in appearance. On the nose it delivers a lovely fruit-driven style with lifted aromas of blackcurrants, plums, gentle spices and hints of vanilla oak. ", region: "South Australia", variety: "Shiraz", alcohol: "14.5%", volume: "750ml"}},
                {productImg: betheone_p4, productName: "SELECTION SHIRAZ CABERNET", productPrice: "72", productLink: "/wines/betheone/2", category: "Betheone", linkName: "SELECTION SHIRAZ CABERNET", qty: "750ml",
                    viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p4, title: "SELECTION SHIRAZ CABERNET", desc: "Deep purple in colour, this is a well-balanced wine showing blackcurrant jam and an elegant leafiness with a hint of mocha oak character.", region: "South Australia", variety: "Shiraz Cabernet", alcohol: "14.5%", volume: "750ml"}},
                {productImg: betheone_p5, productName: "SELECTION MERLOT", productPrice: "72", productLink: "/wines/betheone/3", category: "Betheone", linkName: "SELECTION MERLOT", qty: "750ml",
                    viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p5, title: "SELECTION MERLOT", desc: "This Merlot is a fresh fruit driven example of this classic varietal. The wine has a medium, garnet colour with aromas of mint, tobacco and eucalyptus with a hint of oak. ", region: "South Australia", variety: "Merlot", alcohol: "14%", volume: "750ml"}},
                {productImg: betheone_p6, productName: "SELECTION CHARDONNAY", productPrice: "72", productLink: "/wines/betheone/4", category: "Betheone", linkName: "SELECTION CHARDONNAY", qty: "750ml",
                    viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p6, title: "SELECTION CHARDONNAY", desc: "This Chardonnay is dominated by fresh citrus blossom melon and fresh apricot aromas with seamless aging complexity. The palate overflows with ripe fruit characters of melon, citrus and ripe stone fruit. This special release Chardonnay has aged in oak for 12 months.", region: "South Australia", variety: "CHARDONNAY", alcohol: "13.5%", volume: "750ml"}},
                {productImg: betheone_p7, productName: "RESERVE SHIRAZ CABERNET", productPrice: "72", productLink: "/wines/betheone/5", category: "Betheone", linkName: "RESERVE SHIRAZ CABERNET", qty: "750ml",
                    viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p7, title: "RESERVE SHIRAZ CABERNET", desc: "This is a bright red wine with a ruby red color. Strong and balanced aroma with aromas of spices and vegetables. The taste is straight and full, with ripe fruit and a pleasant smoky aftertaste as a result of aging in the barrel. It is characterized by its concentration and solid texture, with a comfortable and perfect finish. This is an expressive and balanced wine with a lasting aftertaste. ", region: "Langhorne Creek", variety: "Shiraz Cabernet", vintage: "contact for more details", alcohol: "14.5%", volume: "750ml"}},
                {productImg: betheone_p8, productName: "RESERVE CREEK SHIRAZ", productPrice: "72", productLink: "/wines/betheone/6", category: "Betheone", linkName: "RESERVE CREEK SHIRAZ", qty: "750ml",
                    viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p8, title: "RESERVE CREEK SHIRAZ", desc: "This wine has a bright, garnet colour with complex fruity aromas of blackberries and plums with cardamom bodied, balanced tannins merging with cloves and black pepper spice. ", region: "Langhorne Creek", variety: "Shiraz", vintage: "contact for more details", alcohol: "14.5%", volume: "750ml"}},
                {productImg: betheone_p9, productName: "RESERVE CABERNET SAUVIGNON", productPrice: "72", productLink: "/wines/betheone/7", category: "Betheone", linkName: "RESERVE CABERNET SAUVIGNON", qty: "750ml",
                    viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p9, title: "RESERVE CABERNET SAUVIGNON", desc: "This wine is a nipe and juicy example of this classic varietal. The wine has a bright, garnet colour with complex fruity aromas of blackcurrant, raspberry and tobacco with a hint of sage, thyme and capsicum. The wine was matured in oak for 12 months prior to bottling. ", region: "Langhorne Creek", variety: "CABERNET SAUVIGNON", vintage: "contact for more details", alcohol: "14.5%", volume: "750ml"}},
                {productImg: betheone_p10, productName: "BETHEONE SHIRAZ", productPrice: "72", productLink: "/wines/betheone/8", category: "Betheone", linkName: "BETHEONE SHIRAZ", qty: "750ml",
                    viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p10, title: "BETHEONE SHIRAZ", desc: "Medium bodies elegant red wine. With aromas of dark plums, mulberries, rasberry and sour cherry. The palate is balanced with rich, fresh red fruit, subtle toasty oak and fine, velvety tannin.", region: "South Australia", variety: "Shiraz", alcohol: "14%", volume: "750ml"}},
                {productImg: betheone_p6, productName: "BETHEONE CHARDONNAY", productPrice: "72", productLink: "/wines/betheone/9", category: "Betheone", linkName: "BETHEONE CHARDONNAY", qty: "750ml",
                    viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p6, title: "BETHEONE CHARDONNAY", desc: "The Chardonnay is dominated by fresh citrus blossom, melon and fresh apricot aromas with some lees aging complexity. The palate overflows with ripe fruit characters of melon, citrus and ripe stone fruit. This special release Chardonnay has aged in oak barrels for 6 months.", region: "South Australia", variety: "Chardonnay", alcohol: "13%", volume: "750ml"}},
                {productImg: betheone_p11, productName: "PINK MOSCATO", productPrice: "72", productLink: "/wines/betheone/10", category: "Betheone", linkName: "PINK MOSCATO", qty: "750ml",
                    viewedDetails: {prodHeader: betheone_p1_header, prodImage: betheone_p11, title: "PINK MOSCATO", desc: "Light pink in colour the bouguet is fruity with a delicate floral note. The palate is sweet but balanced, showing fruity, floral flavours that give freshness and vibrancy. The soft bubbles linger on the palate for a clean, sweet finish.", alcohol: "7%", volume: "750ml", region: "South Australia",}},
            ]
        }
    },
    {
        id: 5,
        target: "wines",
        link: '/wines/dudies',
        img: ow2,
        productPageInfo: {
            title: "Dudies",
            desc: "For those who yearn for the unusual, the colourful, the fun, we present fine Australian wines that are jam-packed with personality and great character. Meet the South Australian Dudies: Cabernet Merlot, the romantic who loses themselves in this velvety smooth combination. Shiraz Cabernet, the cheeky one, just like this unique Australian blend and Semillon Sauvignon Blanc, the free spirit. Easy to drink with a deliciously fresh, crisp finish.",
            // pageHeader: dudies_header,
            pageVideoHeader: dudies_header_video,
            mainLink: "WINES", mainLinkhref: "wines",
            products: [
                {productImg: dudies_p1, productName: "Dudies Shiraz Cabernet", productPrice: "72", productLink: "/wines/dudies/1", category: "Dudies", linkName: "Shiraz Cabernet", qty: "750ml",
                    viewedDetails: {prodHeader: dudies_header, prodImage: dudies_p1, title: "Shiraz Cabernet", desc: "Deep purple in colour, this is a well-balanced wine showing blackcurrant jam and subtle raspberry notes from the merlot, with underlying vanilla and mocha oak characters. The palate delivers flavours of blackcurrant and cherry with superb length.", region: "South Australia", variety: "Shiraz Cabernet", wine_style: "Dry", vintage: "2019", alcohol: "14%", volume: "750ml"}},
                {productImg: dudies_p3, productName: "Dudies Cabernet Merlot", productPrice: "72", productLink: "/wines/dudies/2", category: "Dudies", linkName: "Cabernet Merlot", qty: "750ml",
                    viewedDetails: {prodHeader: dudies_header, prodImage: dudies_p3, title: "Cabernet Merlot", desc: "Deep and bright red. It has the aroma of black currant and black olives. Lively and very flavorful, the palate is silky smooth, round and full, with firm tannins and a long finish.", region: "South Australia", variety: "Cabernet Merlot", wine_style: "Dry", vintage: "2019", alcohol: "14%", volume: "750ml"}},
                {productImg: dudies_p2, productName: "DUDIES SEMILLON BLANC/CHARDONNAY", productPrice: "72", productLink: "/wines/dudies/3", category: "Dudies", linkName: "Semillon Sauvignon Blanc", qty: "750ml",
                    viewedDetails: {prodHeader: dudies_header, prodImage: dudies_p2, title: "DUDIES SEMILLON BLANC/CHARDONNAY", desc: "Semillon Sauvignon: This wine exhibits aromas of cut grass and pine needle herbaceouness with subtletropical and citrus blossom characters. The palate of this elegant wine is intense and concentrated, with lychee and asparagus flovours balanced by citrus, guava, and rockmelon. Chardonnay: This Chardonnay has aged in oak barrels for 6 months, dominated by fresh citrus blossom, melon and fresh apricot aromas with seemless aging complexity. The palate overflows.", region: "South Australia", variety: "Semillon Sauvignon Blanc", wine_style: "Dry", vintage: "2019", alcohol: "12%", volume: "750ml"}},
            ]
        }
    },
    {
        id: 6,
        target: "wines",
        link: '/wines/mayan3',
        img: ow3,
        productPageInfo: {
            title: "Mayan",
            desc: "The ancient Mayans developed the science of astronomy, calendar systems and hieroglyphic writing thousands of years ago. Our Mayan range of wines is our tribute to this great civilisation, each wine named after three of the Mayan’s five ages of the sun.",
            pageHeader: mayan_header,
            mainLink: "WINES", mainLinkhref: "wines",
            products: [
                {productImg: mayan_p1, productName: "MATLACTIL ART", productPrice: "72", productLink: "/wines/mayan3/1", category: "Mayan", linkName: "MATLACTIL ART", qty: "750ml",
                    viewedDetails: {prodHeader: mayan_p1_header, prodImage: mayan_p1, title: "MATLACTIL ART", desc: "Sweet plum and rich ripe berry flavors, round taste, balanced structure, soft tannins, lasting aftertaste.", region: "South Australia", variety: "Blended", wine_style: "Dry", vintage: "NV", alcohol: "13.5%", volume: "750ml"}},
                {productImg: mayan_p2, productName: "TZONTLILIC", productPrice: "72", productLink: "/wines/mayan3/2", category: "Mayan", linkName: "TZONTLILIC", qty: "750ml",
                    viewedDetails: {prodHeader: mayan_p1_header, prodImage: mayan_p2, title: "TZONTLILIC", desc: "Clear blueberry aroma with a hint of chocolate and black truffle. The palate is full, easy to drink and has a long returning sweetness.", region: "South Australia", variety: "Blended", wine_style: "Dry", vintage: "NV", alcohol: "13.5%", volume: "750ml"}},
                {productImg: mayan_p3, productName: "TLEYQUIYAHUILLO", productPrice: "72", productLink: "/wines/mayan3/3", category: "Mayan", linkName: "TLEYQUIYAHUILLO", qty: "750ml",
                    viewedDetails: {prodHeader: mayan_p1_header, prodImage: mayan_p3, title: "TLEYQUIYAHUILLO", desc: "Ripe juicy berries and spices complement each other. This is a soft, sweet, wellbalanced, elegant and classic south Australian blend. ", region: "South Australia", variety: "Blended", wine_style: "Dry", vintage: "NV", alcohol: "13.5%", volume: "750ml"}},
            ]
        }
    },
    {
        id: 7,
        target: "wines",
        link: '/wines/magic',
        img: ow4,
        productPageInfo: {
            title: "Magic",
            desc: "These wines are the embodiment of magic conjured by Australia Wine Makers from the century-old wine cultures of South Australia’s iconic regions of the Barossa Valley, McLaren Vale, Clare Valley and the Coonawarra, and their star varietals.",
            pageHeader: magic_header,
            mainLink: "WINES", mainLinkhref: "wines",
            products: [
                {productImg: magic_p1, productName: "MAGIC A", productPrice: "72", productLink: "/wines/magic/1", category: "Magic", linkName: "MAGIC A", qty: "750ml",
                    viewedDetails: {prodHeader: magic_p1_header, prodImage: magic_p1, title: "MAGIC A", desc: "Full of rich flavors of chocolate, plum, mulberry and ripe forest berries, full bodied with a velvety touch on the tongue and a long finish.", region: " Barossa Valley", variety: "Shiraz", wine_style: "Dry", vintage: "2018", alcohol: "15%", volume: "750ml"}},
                {productImg: magic_p2, productName: "MAGIC J", productPrice: "72", productLink: "/wines/magic/2", category: "Magic", linkName: "MAGIC J", qty: "750ml",
                    viewedDetails: {prodHeader: magic_p1_header, prodImage: magic_p2, title: "MAGIC J", desc: "Ruby and deep purple hues. Violet,forested fruit, liquorice and intense mocha. Rich fruit flavor and silky tannins, well balanced and long finish.", region: "Clare Valley", variety: "Cabernet Malbec", wine_style: "Dry", vintage: "2018", alcohol: "15.1%", volume: "750ml"}},
                {productImg: magic_p3, productName: "MAGIC K", productPrice: "72", productLink: "/wines/magic/3", category: "Magic", linkName: "MAGIC K", qty: "750ml",
                    viewedDetails: {prodHeader: magic_p1_header, prodImage: magic_p3, title: "MAGIC K", desc: "Rich aroma of raspberry and blackcurrant, accompanied with musk and leather aftertaste, as well as a hint of cedar and tobacco.", region: "Coonawarra", variety: "Cabernet Sauvignon", wine_style: "Dry", vintage: "2018", alcohol: "14.9%", volume: "750ml"}},
                {productImg: magic_p4, productName: "MAGIC Q", productPrice: "72", productLink: "/wines/magic/4", category: "Magic", linkName: "MAGIC Q", qty: "750ml",
                    viewedDetails: {prodHeader: magic_p1_header, prodImage: magic_p4, title: "MAGIC Q", desc: "On the palate, there is a hint of plum, mulberry and ripe forest pulp, with a perfect display of fruit flavors and extraordinary colours. ", region: "Mclaren Vale", variety: "Shiraz", wine_style: "Dry", vintage: "2018", alcohol: "15.4%", volume: "750ml"}},
                {productImg: magic_p5, productName: "MAGIC 7 CABERNET SAUVIGON", productPrice: "72", productLink: "/wines/magic/5", category: "Magic", linkName: "MAGIC 7 CABERNET SAUVIGON", qty: "750ml",
                    viewedDetails: {prodHeader: magic_p1_header, prodImage: magic_p5, title: "MAGIC 7 CABERNET SAUVIGON", desc: "It is dark and charcoal-colored. Intense aromas of rasberry, blackberry and blackcurrant with hint sof musky and leather on finish. Explosive fruit flavors, medium to full-bodied, soft tannins and a dry pepper on finish.", region: "South Australia", variety: "Cabernet Sauvignon ", wine_style: "Dry", vintage: "2018", alcohol: "14.5%", volume: "750ml"}},
                {productImg: magic_p6, productName: "MAGIC 7 SHIRAZ", productPrice: "72", productLink: "/wines/magic/6", category: "Magic", linkName: "MAGIC 7 SHIRAZ", qty: "750ml",
                    viewedDetails: {prodHeader: magic_p1_header, prodImage: magic_p6, title: "MAGIC 7 SHIRAZ", desc: "Deep red and dark black, complex texture with layered aromas. Smoky coffee beans and chocolate flavors. Intense blackcurrant and blackberry, and hints of fur , earthy and spicy cedar. ", region: "South Australia", variety: "Shiraz", wine_style: "Dry", vintage: "2018", alcohol: "14.5%", volume: "750ml"}},
                {productImg: magic_p7, productName: "MAGIC 9 RIESLING", productPrice: "72", productLink: "/wines/magic/7", category: "Magic", linkName: "MAGIC 9 RIESLING", qty: "750ml",
                    viewedDetails: {prodHeader: magic_p1_header, prodImage: magic_p7, title: "MAGIC 9 RIESLING", desc: "One of the finest wine makers and mates David O’Leary and Nick Walker took the punt’ in 2000 and followed their philosophy, and to create hand-crafted wines in small batches from their own vineyards and here we present a special Riesling to you.", region: "Clare Valley", variety: "Riesling", wine_style: "Dry", vintage: "2018", alcohol: "11.5%", volume: "750ml"}},
            ]
        }
    },
    {
        id: 8,
        target: "wines",
        link: '/wines/fsol',
        img: ow5,
        productPageInfo: {
            title: "Love in Four Seasons",
            desc: "Love in Four Seasons is our proclamation to L’amant, French for ‘the lover’. Each wine epitomises a season in the lucky year of ’88, representing the four phases of love. Spring of 88 is the season of unrequited love. Summer sees the ardent chase. Autumn marks falling in love, while finally, Winter is the season of peaceful love.",
            pageHeader: fseason_header,
            mainLink: "WINES", mainLinkhref: "wines",
            products: [
                {productImg: fseason_p1, productName: "Spring of 88", productPrice: "72", productLink: "/wines/fsol/1", category: "Love in Four Seasons", linkName: "Spring of 88", qty: "750ml",
                    viewedDetails: {prodHeader: non_alc_spring88_header, prodImage: fseason_p1, title: "Spring of 88", desc: "This grape juice shows bright, light yellow colour and a fresh bouquet, with fine and smooth bubbles. The palate is medium bodied and rich in full fruit flavours.", region: "South Australia", variety: "100% White sparkling grape juice", wine_style: "Sweet", vintage: "NV", alcohol: "0%", volume: "750ml"}},
                {productImg: fseason_p2, productName: "Summer of 88", productPrice: "72", productLink: "/wines/fsol/2", category: "Love in Four Seasons", linkName: "Summer of 88", qty: "750ml",
                    viewedDetails: {prodHeader: fseason_p2_header, prodImage: fseason_p2, title: "Summer of 88", desc: "This wine has beautiful aromas of flower petals and musk lollies. The palate begins with lovely fruity flavours that continue across the soft and sweet palate. The sweetness is balanced and freshened by delicate bubbles that linger on the palate.", region: "South Australia", variety: "Moscato", wine_style: "Sweet", vintage: "NV", alcohol: "7.5%", volume: "750ml"}},
                {productImg: fseason_p3, productName: "Autumn of 88", productPrice: "72", productLink: "/wines/fsol/3", category: "Love in Four Seasons", linkName: "Autumn of 88", qty: "750ml",
                    viewedDetails: {prodHeader: fseason_p1_header, prodImage: fseason_p3, title: "Autumn of 88", desc: "Light pink in colour this wine’s bouquet is fruity with a delicate floral note.The palate is sweet yet balanced, showing fruity floral flavours that give freshness and vibrancy. Soft bubbles linger on the palate for a clean, sweet finish.", region: "South Australia", variety: "Moscato", wine_style: "Sweet", vintage: "NV", alcohol: "8.5%", volume: "750ml"}},
                {productImg: fseason_p4, productName: "Winter of 88", productPrice: "72", productLink: "/wines/fsol/4", category: "Love in Four Seasons", linkName: "Winter of 88", qty: "750ml",
                    viewedDetails: {prodHeader: non_alc_spring88_header, prodImage: fseason_p4, title: "Winter of 88", desc: "This juice is bright dark purple in colour and has a fresh bouquet and fine, smooth bubbles. The palate is medium-bodied and rich in full fruit flavours.", region: "South Australia", variety: "100% Dark sparkling grape juice", wine_style: "Sweet", vintage: "NV", alcohol: "0%", volume: "750ml"}},
            ]
        }
    },
    // {
    //     id: 9,
    //     target: "wines",
    //     link: '/wines/luigi_bosca',
    //     img: ow6,
    //     productPageInfo: {
    //         title: "Luigi Bosca",
    //         desc: "The history of Bodega Luigi Bosca dates back to the turn of the 20th century when the Arizu family from Basque country and the Bosca family from Piemonte joined efforts upon their arrival in Argentina. After four generations, the Arizu family still runs the day-to-day operations as the winery charges ahead well into the 21st century. Testimonio is sourced from 40-year old vines in Vistalba, the first wine area you encounter upon leaving the city of Mendoza. The grapes are hand-harvested, fermented under the strict watch of the winemaker and sent to rest in French oak for eight months. It’s bright, intense and voluptuous with typical Mendoza fruit and a backbone of vanilla from the time in barrel. Deep and elegant, this malbec will develop very well for next decade if you have the patience.",
    //         pageHeader: luigi_header,
    //         mainLink: "WINES", mainLinkhref: "wines",
    //         products: [
    //             {productImg: luigi_p1, productName: "TESTIMONIO RESERVE BLEND", productPrice: "72", productLink: "/wines/luigi_bosca/1", category: "Luigi Bosco", linkName: "TESTIMONIO RESERVE BLEND", qty: "750ml",
    //                 viewedDetails: {prodHeader: luigi_p1_header, prodImage: luigi_p1, title: "TESTIMONIO RESERVE BLEND", desc: "This is a bright red wine with a ruby red color. Strong and balanced aroma with aromas of spices and vegetables. The taste is straight and full, with ripe fruit and a pleasant smoky aftertaste as a result of aging in the barrel. It is characterized by its concentration and solid texture, with a comfortable and perfect finish. This is an expressive and balanced wine with a lasting aftertaste.", region: "Argentina", variety: "Cabernet Sauvignon 50% Syrah 35%, Dana 15%", vintage: "NV", alcohol: "14.4%", volume: "750ml"}},
    //             {productImg: luigi_p2, productName: "TESTIMONIO MALBEC", productPrice: "72", productLink: "/wines/luigi_bosca/2", category: "Luigi Bosco", linkName: "TESTIMONIO MALBEC", qty: "750ml",
    //                 viewedDetails: {prodHeader: luigi_p1_header, prodImage: luigi_p2, title: "TESTIMONIO MALBEC", desc: "TThe ancient vines have a bright and lively color. The body presents subtle tones of red fruit, floral aromas and vanilla, which are typical characteristics of balanced partial aging in oak barrels. This is a rich and juicy red wine with very strong tannin structure and balanced acidity. This is a complex and round wine with a long taste and a deep and elegant aftertaste. ", region: "Argentina", variety: "Mal Bee", vintage: "NV", alcohol: "14%", volume: "750ml"}},
    //             {productImg: luigi_p3, productName: "TABU MALBEC", productPrice: "72", productLink: "/wines/luigi_bosca/3", category: "Luigi Bosco", linkName: "TABU MALBEC", qty: "750ml",
    //                 viewedDetails: {prodHeader: luigi_p1_header, prodImage: luigi_p3, title: "TABU MALBEC", desc: "It is a bright violet, classic cherry, blackberry aroma and spicy and delicate floral fragrance. On the palate, it has a rich fruity aroma, a full and silky body, mature tannins and balanced acidity. The body structure is reasonable and elegant. It is a wine with excellent clarity and  modern style. ", region: "Argentina", variety: "Mal bee", vintage: "NV", alcohol: "14%", volume: "750ml"}},
    //         ]
    //     }
    // },
    {
        id: 10,
        target: "wines",
        link: '/wines/mayan6',
        img: ow7,
        productPageInfo: {
            title: "Mayan",
            desc: "The ancient Mayans developed the science of astronomy, calendar systems and hieroglyphic writing thousands of years ago. Our Mayan range of wines is our tribute to this great civilisation, each wine named after three of the Mayan’s five ages of the sun.",
            pageHeader: mayan6_header,
            mainLink: "WINES", mainLinkhref: "wines",
            products: [
                {productImg: mayan6_p1, productName: "MAYAN WINEMAKER SELECTION", productPrice: "72", productLink: "/wines/mayan6/1", category: "Mayan", linkName: "MAYAN WINEMAKER SELECTION", qty: "750ml",
                    viewedDetails: {prodHeader: mayan_p1_header, prodImage: mayan6_p1, title: "MAYAN WINEMAKER SELECTION", desc: "This wine exudes strong aromas of raspberries, dried plums and figs, with a slight spicy touch. The palate is juicy, with round tannins, a touch of coffee and chocolate, and a long aftertaste.", region: "Central Valley Chile", variety: "Malbec 37% Carigne 33% Cabernet Sauvignon 24% Syrah 6%", vintage: "NV", alcohol: "14%", volume: "750ml"}},
                {productImg: mayan6_p2, productName: "MAYAN FAMILY RESERVE", productPrice: "72", productLink: "/wines/mayan6/2", category: "Mayan", linkName: "MAYAN FAMILY RESERVE", qty: "750ml",
                    viewedDetails: {prodHeader: mayan_p1_header, prodImage: mayan6_p2, title: "MAYAN FAMILY RESERVE", desc: "This wine has a ruby color with violet tinges. Aromas of fresh fruits, such as strawberries and plums, as well as the popular aromas of licorice, fennel, chocolate and champagne. Perfectly balanced with ripe, fresh tannins and a long refreshing finish.", region: "Maipo, Chile ", variety: "Cabernet Sauvignon", vintage: "NV", alcohol: "14%", volume: "750ml"}},
                {productImg: mayan6_p3, productName: "MAYAN GRAND RESERVE", productPrice: "72", productLink: "/wines/mayan6/3", category: "Mayan", linkName: "MAYAN GRAND RESERVE", qty: "750ml",
                    viewedDetails: {prodHeader: mayan_p1_header, prodImage: mayan6_p3, title: "MAYAN GRAND RESERVE", desc: "The body of this wine exudes rich aromas of red wine and plums, as well as aromas of spices, anise and toranilla chocolate. The taste is balanced and the aftertaste is long. ", region: "Central Valley-CHILE", variety: "Red Blend", vintage: "NV", alcohol: "14%", volume: "750ml"}},
                {productImg: mayan6_p4, productName: "MAYAN GRAND RESERVE 2", productPrice: "72", productLink: "/wines/mayan6/4", category: "Mayan", linkName: "MAYAN GRAND RESERVE 2", qty: "750ml",
                    viewedDetails: {prodHeader: mayan_p1_header, prodImage: mayan6_p4, title: "MAYAN GRAND RESERVE 2", desc: "This wine has a violet color in its dark color. The wine is full-bodied, with aromas of cherries, figs, violets and white fruits such as apricots and pears. The palate is long, complex, juicy, with soft tannins. The final aftertaste provides good acidity and freshness to achieve the best balance. ", region: "Central Valley-CHILE", variety: "CABERNET SAUVIGNON", vintage: "NV", alcohol: "14%", volume: "750ml"}},
                {productImg: mayan6_p5, productName: "MAYAN SELECTION", productPrice: "72", productLink: "/wines/mayan6/5", category: "Mayan", linkName: "MAYAN SELECTION", qty: "750ml",
                    viewedDetails: {prodHeader: mayan_p1_header, prodImage: mayan6_p5, title: "MAYAN SELECTION", desc: "This wine has a strong deep purple. The complex aroma is composed of black fruits and white pepper. Elegant and round tannins are defined by powerful structure and vivid freshness. It goes best with spicy food, red meat, chicken, and pasta. ", region: "Maule Valley-CHILE", variety: "CARMENERE", vintage: "NV", alcohol: "13.5%", volume: "750ml"}},
                {productImg: mayan6_p6, productName: "MAYAN SELECTION 2", productPrice: "72", productLink: "/wines/mayan6/6", category: "Mayan", linkName: "MAYAN SELECTION 2", qty: "750ml",
                    viewedDetails: {prodHeader: mayan_p1_header, prodImage: mayan6_p6, title: "MAYAN SELECTION 2", desc: "The fresh citrus flavor is mixed with pineapple and fresh vanilla. Moderate acidity, balanced taste and mineral aftertaste. It is recommended to drink in the temperature range of 6-8 C.", region: "Maule Valley-CHILE", variety: "SAUVIGNON BLANC ", vintage: "NV", alcohol: "13%", volume: "750ml"}},
            ]
        }
    },
    {
        id: 11,
        target: "wines",
        link: '/wines/goldener_sands',
        img: ow8,
        productPageInfo: {
            title: "Goldener Sands",
            desc: "Goldener Sands wines are exclusively produced for AWM by Arabella Winery, the first privatized winery in the Robertson region, currently run by the fourth and the fifth generations of the family. The winery has won hundreds of awards, with an annual crushing capacity of 3,000 tons, its own vineyards of 375 hectares, and an annual increase of 10 hectares of grapes. It is one of the few integrated wineries in South Africa, including press storage, bottling, and wine cellars.The owner of the Arabella Winery, Stephen de Wet , is a horse racing enthusiast and champion. Hence the name of the winery comes from the beautiful and strong Arabian horse. At the Michelangelo International Wine Challenge, Arabella Sila won two gold medals. At Arabella, we have a very simple goal: we are committed to brewing the best flavorful wines and creating more cost-effective possibilities.",
            pageHeader: goldener_header,
            mainLink: "WINES", mainLinkhref: "wines",
            products: [
                {productImg: goldener_p1, productName: "GRAND RESERVE PINOTAGE", productPrice: "72", productLink: "/wines/goldener_sands/1", category: "Goldener Sands", linkName: "GRAND RESERVE PINOTAGE", qty: "750ml",
                    viewedDetails: {prodHeader: goldener_p1_header, prodImage: goldener_p1, title: "GRAND RESERVE PINOTAGE", desc: "This wine has a deep purple color, a vibrant body, a fusion of freshly picked red berries, cherries and raspberries intertwined with oak aromas of vanilla, cocoa and smoked meat. The palate is rich and mature, with aromas of cherries, dark chocolate, cinnamon and black pepper lingering in the mouth for a long time. This wine has been fermented in oak barrels for 12 months, with a strong structure and a long aftertaste. This wine is very balanced, and the current performance is very strong. It will reach the drinking period in the next five years, and it will be softer, sweeter and more layered.", region: "Robertson, South Africa", vintage: "NV", alcohol: "14.5%", volume: "750ml"}},
                {productImg: goldener_p2, productName: "GOLDENER SANDS STAR", productPrice: "72", productLink: "/wines/goldener_sands/2", category: "Goldener Sands", linkName: "GOLDENER SANDS STAR", qty: "750ml",
                    viewedDetails: {prodHeader: goldener_p1_header, prodImage: goldener_p2, title: "GOLDENER SANDS STAR", desc: "This wine has aromas of green apple, guava, pear and honey. The entrance has a faint mineral flavour, and the middle section is thick. The acidity is balanced and the aftertaste is long lasting. Very suitable for daily drinking.", region: "Western Cape, South Africa", vintage: "NV", alcohol: "13%", volume: "750ml", variety: "Chenin Blanc"}},
                {productImg: goldener_p3, productName: "GOLDENER SANDS LUNA", productPrice: "72", productLink: "/wines/goldener_sands/3", category: "Goldener Sands", linkName: "GOLDENER SANDS LUNA", qty: "750ml",
                    viewedDetails: {prodHeader: goldener_p1_header, prodImage: goldener_p3, title: "GOLDENER SANDS LUNA", desc: "The wine has a deep red color with aromas of ripe blackberry fruit, black pepper and licorice. The taste is round, the wine body is soft, and the wine is fullbodied, which can be matched with a variety of foods", region: "Western Cape, South Africa", vintage: "NV", alcohol: "14.5%", volume: "750ml", variety: "Cabernet Sauvignon"}},
                {productImg: goldener_p4, productName: "GOLDENER SANDS SHINE", productPrice: "72", productLink: "/wines/goldener_sands/4", category: "Goldener Sands", linkName: "GOLDENER SANDS SHINE", qty: "750ml",
                    viewedDetails: {prodHeader: goldener_p1_header, prodImage: goldener_p4, title: "GOLDENER SANDS SHINE", desc: "The wine is ruby red. It exudes fruity aromas of plum, blackberry and mocha chocolate. Licorice and sweet tobacco, mixed with French oak. The palate is full, with strong tannins.", region: "Western Cape, South Africa", vintage: "NV", alcohol: "14.5%", volume: "750ml", variety: "PINOTAGE"}},
            ]
        }
    },
    {
        id: 12,
        target: "whiskys",
        link: '/whiskys/ogilhinn_nessia',
        img: our_whisky1,
        productPageInfo: {
            title: "OGILHINN NESSIA",
            desc: "Once declared by Pope Francis “Scotch whisky is the real holy water”. AWM brings you Ogilhinn Nessia, presenting the purest from the high peaks.",
            pageHeader: ogilhinn_header,
            mainLink: "WHISKYS",mainLinkhref: "whiskys", 
            products: [
                {productImg: ogilhinn_p1, productName: "INVERGORDON", productPrice: "72", productLink: "/whiskys/ogilhinn/1", category: "OGILHINN NESSIA", linkName: "INVERGORDON", qty: "700ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: ogilhinn_p1, title: "INVERGORDON", desc: "From the legendary lnvergordon Inver Gordon winery. Unique single-barrel canisters specially bottled for venerable whisky enthusiasts. Non-cold filtration under the bourbon barrel.This whisky has honey floral scent, smooth palate and long finish.", region: "Highland", alcohol: "51.8%", volume: "700ml", subInfo: "32YO Single Grain Cask Strength"}},
                {productImg: ogilhinn_p2, productName: "GLENRONTHES", productPrice: "72", productLink: "/whiskys/ogilhinn/2", category: "OGILHINN NESSIA", linkName: "GLENRONTHES", qty: "700ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: ogilhinn_p2, title: "GLENRONTHES", desc: "From the picturesque Glenronthes Winery. You can enjoy it directly or order water. With the advantages of non-cold filtration and bourbon maturation, the taste of this malt is like a sweet and warm whisper of bees, ending with a long and joyful end.", region: "Speyside", alcohol: "59.8%", volume: "700ml", subInfo: "12YO Cask Strength"}},
                {productImg: ogilhinn_p3, productName: "DAILUAINE", productPrice: "72", productLink: "/whiskys/ogilhinn/3", category: "OGILHINN NESSIA", linkName: "DAILUAINE", qty: "700ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: ogilhinn_p3, title: "DAILUAINE", desc: "The soft process water from Balliemullich (Balliemullich) flows down from the ben rinnes. Cooling water from Green Creek or Spey River. Aged in sherry barrels, the wine is rich in body, rich in aromas of dried fruits and fruit cakes, rich in taste, strong in oiliness, sweet in the front, slightly tannins, dark chocolate in the back, and full-bodied. ", region: "Speyside", alcohol: "57.6%", volume: "700ml", subInfo: "12YO Sherry Cask Strength"}},
                {productImg: ogilhinn_p4, productName: "MACALLAN", productPrice: "72", productLink: "/whiskys/ogilhinn/4", category: "OGILHINN NESSIA", linkName: "MACALLAN", qty: "700ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: ogilhinn_p4, title: "MACALLAN", desc: "No water is added before bottling, so the taste is quite strong. Aroma: Light, buttery vanilla mixes with cedar wood and Canadian maple. Taste: rich layers of dark chocolate and damson; a hint of liquorice adds to the complexity Aftertaste: It leaves an intense feeling in the mouth; liquorice and vanilla endure.", region: "Highland", alcohol: "54%", volume: "700ml", subInfo: "12YO Sherry Cask Strength"}},
                {productImg: ogilhinn_p5, productName: "DISCOVERY", productPrice: "72", productLink: "/whiskys/ogilhinn/5", category: "OGILHINN NESSIA", linkName: "DISCOVERY", qty: "750ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: ogilhinn_p5, title: "DISCOVERY", desc: "Matured for a minimum of three years in American oak barrels, this whisky has an inviting warm golden hue, with flavours of tobacco and smoked cigar with a full finish on the palate. Perfect over ice or with your favorite mixer.", region: "Islay", alcohol: "46%", volume: "750ml", subInfo: "Islay Single Malt"}},
                {productImg: ogilhinn_p6, productName: "THE CLOUD", productPrice: "72", productLink: "/whiskys/ogilhinn/6", category: "OGILHINN NESSIA", linkName: "THE CLOUD", qty: "750ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: ogilhinn_p6, title: "THE CLOUD", desc: "This single malt scotch whisky comes from a range of aging casks, selected by our cellar master. Produced and allowed to age in seasoned casks in the distillery warehouses located by the river Spey deep in the Highland of Scotland. This particular location has allowed the maturing to enjoy the gentle winds coming i over the North Sea. Enjoy our smooth scotch whisky straight, or with a addition of spring water", region: "Highland", alcohol: "46%", volume: "750ml", subInfo: "Highland Single Malt"}},
                {productImg: ogilhinn_p7, productName: "STORMS", productPrice: "72", productLink: "/whiskys/ogilhinn/7", category: "OGILHINN NESSIA", linkName: "STORMS", qty: "750ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: ogilhinn_p7, title: "STORMS", desc: "Matured for a minimum of three years in American oak barrels, this whisky has an inviting warm golden hue, with flavours of vanilla and a hint of fresh pear and honeycomb giving a pleasantly sweet and mild finish on the palate. Perfect over ice or with your favorite mixer.", region: "Speyside", alcohol: "46%", volume: "750ml", subInfo: "Speyside Single Malt"}},
                {productImg: ogilhinn_p8, productName: "CHOICE", productPrice: "72", productLink: "/whiskys/ogilhinn/8", category: "OGILHINN NESSIA", linkName: "CHOICE", qty: "700ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: ogilhinn_p8, title: "CHOICE", desc: "Ogilhinn Nessia, A non chilled filtered Blended Scotch Whisky of outstanding quality, contains several Single Malts and Grain Scotch Whiskies from distilleries located in the Highlands and the West Coast of Scotland where the casks are matured, capturing the crisp Atlantic Breeze which provides a unique ageing process of intense flavour. This Non Chilled Filtered Blended Scotch Whisky is sweet, slightly earthy with hints of butterscotch and honey. A golden sunset appearance and a lingering nose of freshness with memories of the Atlantic Ocean.", region: "Highlands", alcohol: "40%", volume: "700ml", subInfo: "BSW"}},
                {productImg: ogilhinn_p9, productName: "VOYAGE", productPrice: "72", productLink: "/whiskys/ogilhinn/9", category: "OGILHINN NESSIA", linkName: "VOYAGE", qty: "700ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: ogilhinn_p9, title: "VOYAGE", desc: "This Blended scotch whisky was produced and allowed to age in casks in the Distinllery warehouses located in the Highlands and West of Scotland. This particular location allows the maturing to live the gentle winds coming from the Atlantic Ocean. Enjoy straight or with small addition of spring water.", region: "Highlands", alcohol: "43.8%", volume: "700ml", subInfo: "12YO BSW"}},
                {productImg: ogilhinn_p10, productName: "AUCHROISK", productPrice: "72", productLink: "/whiskys/ogilhinn/10", category: "OGILHINN NESSIA", linkName: "AUCHROISK", qty: "700ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: ogilhinn_p10, title: "AUCHROISK", desc: "The smell of vanilla and a hint of caramel give it a pleasant sweetness and mild ambiguity. Aged in bourbon barrels for at least 3 years, the whiskey has an attractive warm golden hue and a sweet aftertaste. The whiskey is the best choice to drink directly, add ice or use your favourite shaker.", region: "Speyside", alcohol: "46%", volume: "700ml", subInfo: "Speyside Single Malt"}},
            ]
        }
    },
    {
        id: 13,
        target: "whiskys",
        link: '/whiskys/betheone_whisky',
        img: our_whisky2,
        productPageInfo: {
            title: "Betheone Whisky",
            desc: "",
            pageHeader: betheone_whisky_header,
            mainLink: "WHISKYS",mainLinkhref: "whiskys", 
            products: [
                {productImg: betheone_whisky_p1, productName: "SINGLE MALT WHISKY", productPrice: "72", productLink: "/whiskys/betheone_whisky/1", category: "Betheone Whisky", linkName: "SINGLE MALT WHISKY", qty: "500ml",
                    viewedDetails: {prodHeader: whiskys_header, prodImage: betheone_whisky_p1, title: "SINGLE MALT WHISKY Tawny 15 yrs & Shiraz 6 yrs old casks", desc: "This rare and exclusive whisky bottling is a marriage of Barossa Valley Shiraz cask and a small portion of Mclaren Valley Tawny cask. Old beyond its years, this whisky displays hints of both Shiraz and Tawny influence on the nose, a rich spiciness on the palate and a dominant peat smoke finish that leaves you with a satisfying warmth and lingering campfire experience.", region: "South Australia", alcohol: "48%", volume: "500ml"}},
            ]
        }
    },
]

export default data;